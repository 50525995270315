<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
        <a href="/admin/roles/lists">Quản lý Phân quyền </a>
      </li>

      <li class="breadcrumb-item">Thêm mới </li>
    </ol>

    <form method="post" @submit.prevent="mySubmit" id="sendform">
      <div class="container">
        <ul class="nav nav-tabs" role="tablist">
          
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            <br />

            <div class="col-sm-12">
              <div class="card">
                <div class="card-header">
                  <strong>Thông tin </strong>
                  <small>Form</small>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          > Tên phân quyền  <span class="text-danger">*</span></label
                        >
                        <input
                          class="form-control"
                          placeholder=""
                          name="name"
                          required="required"
                          type="text"
                        />
                        <p class="text-danger">{{ error.name }}</p>
                      </div>
                    </div>

                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          > Phòng ban <span class="text-danger">*</span></label
                        >
                          
                         <select class="form-control" name="departmentId" v-if="departments!=null">
                            <optgroup label="Phòng ban">
                                  <option v-for="(v,k) in departments" :key="k" :value="v.id">{{v.name}}</option>
                            </optgroup>
                         </select> 
                      
                      </div>
                    </div>


                     <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          > Chọn danh mục  <span class="text-danger">*</span></label
                        >
                          
                         <select class="form-control" v-model="permissions"  v-if=" cate.parent!=null" multiple="multiple" style="height:200px;" required="required">



                            <option v-for="(p,k_p) in cate.parent" :key="k_p" :value="p.id"> 
                                    {{p.name}}
                            </option>
                         </select> 
                        <p class="text-danger">{{ error.email }}</p>
                      </div>
                    </div>

                    <div class="col-sm-10" style="display: none;">
                      <div class="form-group">
                        <label for="name"
                          >  Gian hàng  <span class="text-danger">*</span></label
                        >
                          
                         <select class="form-control" multiple="multiple"  v-model="flagIndex" style="height:100px;" >
                              <option value=''>Không cho truy cập</option>
                             <option value='1'>Thuê mặt bằng </option>
                             <option value='2'>Thuê quầy vé  </option>


                         </select> 
                        <p class="text-danger">{{ error.email }}</p>
                      </div>
                    </div>
                    <div class="col-sm-10">
                      <div class="form-group">
                        <label for="name"
                          >  Chức năng   <span class="text-danger">*</span></label
                        >
                          
                         <select class="form-control" multiple="multiple"  v-model="flagContent" style="height:300px;" >


                              <option v-for="(f,k) in func" :key="k" :value="f.id">
                                    {{f.name}}

                              </option>
                           


                         </select> 
                        <p class="text-danger">{{ error.email }}</p>
                      </div>
                    </div>



                   



                  </div>
                </div>
              </div>
            </div>
           
          </div>

          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              v-if="!is_load"
            >
              <i class="fa fa-save"></i> Save
            </button>
           
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import { route_admin } from "../../../../router/route_admin";
export default {
  data() {
    return {
      is_load: false,
      error: {
        name: "",
        email: "",
        phone: "",
        check_pass: "",
      },
      departments:null,
      cate:{
        parent:null,
        child:null

      },
      pass: "",
      re_pass: "",
      permissions:[],
      flagIndex:[],
      flagContent:[],
      func:[


      ]
    };
  },
  mounted() {

  

    for(var i=0;i<route_admin.length-1;i++){
        if( route_admin[i].label!=undefined){
            this.func.push({
                id: route_admin[i].name,
                name:route_admin[i].label
            });
        }
    }

    let p2=  axios
      .get(window.my_api + "api/newscate/get-newscates", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.cate.parent = res.data.data.filter((a)=>a.parentId==null);
          this.cate.child=res.data.data.filter((a)=>a.parentId!=null);
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

    let p3=  axios
      .get(window.my_api + "api/department/get-departments", {
        headers: {
          language: this.$i18n.locale,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.departments = res.data.data;
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2,p3]).then(()=>{
          this.is_loading=false;
      });

  },

  methods: {
    alertError() {
      let tt = "";
      for (let i = 0; i < Object.values(this.error).length; i++) {
        if (Object.values(this.error)[i]) {
          tt =
            tt +
            "<ul style='text-align:left;'><li>" +
            Object.values(this.error)[i] +
            "</li></ul>";
        }
      }
      return tt;
    },
    handlePass(e) {
      this.pass = e.target.value;
    },
    handleRePass(e) {
      this.re_pass = e.target.value;
    },
    mySubmit: function () {
     
      let myForm = document.getElementById("sendform");
      let formData = new FormData(myForm);
      for (var pair of formData) {
        if (pair[0] == "name") {
          if (pair[1] == "") {
            this.error.name = "Vui lòng nhập tên.";
          } else {
            this.error.name = "";
          }
        }

      }
      if (
        this.error.name == "" 
       
       
      ) {
       
          this.is_load = true;
          
        formData.append("permissions",this.permissions.join(","));
        formData.append("flagIndex",this.flagIndex.join(","));

        formData.append("flagContent",this.flagContent.join(","));
         
         
          axios
            .post(window.my_api + "api/role/create-or-update-role", formData)
            .then((res) => {
              if (res.status == 200) {
                alert("Thêm thành công.")
                 this.$router.go(this.$router.currentRoute);
                document.getElementById("sendform").reset();
              } else {
                alert("Thêm không thành công, vui lòng liên hệ admin");
              }
              this.is_load = false;
            });
        
      } else {
        console.log(this.alertError());

alert("Vui lòng nhập đầy đủ");
      }
    },
  },
};
</script>